<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.searchWord"
            clearable
            placeholder="板块名称/ID"
          />
        </el-form-item>
        <el-form-item prop="state">
          <el-select
            v-model="formData.status"
            placeholder="上架状态"
            clearable
          >
            <el-option
              v-for="(item, index) in appModuleStatesList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          @click="handleCreateProdModule"
        >
          新建产品版块
        </el-button>
        <el-button
          type="primary"
          @click="handleCreateRealADModule"
        >
          新建广告版块
        </el-button>
      </template>
    </BHeader>

    <el-table
      v-loading="categoryItemListInfo.loading"
      :data="categoryItemListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="sort"
        label="排序"
        min-width="100"
      />
      <el-table-column
        prop="id"
        label="板块ID"
        min-width="100"
      />
      <el-table-column
        prop="title"
        min-width="100"
        label="板块名称"
      >
        <template v-slot="{row}">
          {{ (row.titleDetail || {}).en || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        min-width="modelSum"
        label="列表"
      >
        <template v-slot="{row}">
          <el-button
            type="text"
            @click="handleViewAdList(row)"
          >
            {{ row.modelSum }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="列表样式"
      >
        <template v-slot="{row}">
          {{ (styleListMap[row.style] || {}).name || 'UNKNOWN' }}
        </template>
      </el-table-column>
      <el-table-column
        label="列表样式编号"
      >
        <template v-slot="{row}">
          {{ (styleListMap[row.style] || {}).value || 'UNKNOWN' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        min-width="100px"
        label="上架状态"
      >
        <template v-slot="{row}">
          {{ row.status === 1 ? '上架' : '下架' }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="260"
        label="操作"
      >
        <template v-slot="{row}">
          <BTextButton
            title="管理"
            icon="fa fa-wrench"
            @click="handleViewAdList(row)"
          />
          <BTextButton
            title="编辑版块信息"
            icon="fa fa-pencil"
            @click="handleEditADModule(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="row.status === 1 ? '启用' : '禁用'"
            :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban' }`"
            @click="handleToggleModule(row)"
          />
          <BTextButton
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteCategoryItemAD(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="categoryItemListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryModuleList"
    />
  </Layout>
</template>

<script>
import { appModuleStatesList, styleForListMap } from '@/utils/selectOptions'
import { deleteCategoryItemAD, queryCategoryItemList, toggleCategoryItemAD } from '@/api/APP_Category'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'CategoryItemList',
  data () {
    return {
      appModuleStatesList,
      formData: {
        title: '',
        status: '',
        pageNum: 1,
        pageSize: 20
      },
      categoryItemListInfo: {
        loading: false,
        list: [],
        total: 0
      },
      zoneId: ''
    }
  },

  computed: {
    ...mapState('app', ['appId', 'channel']),
    ...mapGetters('selectOptions', ['modelStyleListMap']),
    styleListMap () {
      console.log(this.modelStyleListMap)
      return {
        ...this.modelStyleListMap,
        ...styleForListMap
      }
    }
  },
  created () {
    this.zoneId = Number(this.$route.query.zoneId)
    this.queryModuleList()
    this.queryModelStyleList()
  },
  activated () {
    this.zoneId = Number(this.$route.query.zoneId)
    this.queryModuleList()
    this.queryModelStyleList()
  },
  methods: {
    ...mapActions('selectOptions', ['queryModelStyleList']),
    handleSearch () {
      this.formData.pageNum = 1
      this.queryModuleList()
    },
    queryModuleList () {
      this.categoryItemListInfo.loading = true
      queryCategoryItemList({
        ...this.formData,
        zoneId: this.zoneId,
        pId: this.$route.query.id,
        appId: this.appId,
        channel: this.channel
      })
        .then(res => {
          if (res.code === 200) {
            this.categoryItemListInfo.list = res.data.list
            console.log(this.categoryItemListInfo.list)
            this.categoryItemListInfo.total = res.data.total
          }
        })
        .finally(() => {
          this.categoryItemListInfo.loading = false
        })
    },
    handleCreateProdModule () {
      this.$router.push({
        name: 'ADModuleCreate',
        query: {
          pId: this.$route.query.id,
          name: this.$route.query.name,
          zoneId: this.zoneId
        }
      })
    },
    handleCreateRealADModule () {
      this.$router.push({
        name: 'RealADModuleCreate',
        query: {
          pId: this.$route.query.id,
          name: this.$route.query.name,
          zoneId: this.zoneId
        }
      })
    },
    handleEditADModule (row) {
      // int64 moduleType = 32;// 模块类型 （1：产品模块，2：广告模块）
      if (row.moduleType === 1) {
        this.$router.push({
          name: 'ADModuleEdit',
          query: {
            id: row.id,
            pId: this.$route.query.id,
            name: this.$route.query.name,
            zoneId: this.zoneId
          }
        })
      } else if (row.moduleType === 2) {
        this.$router.push({
          name: 'RealADModuleEdit',
          query: {
            id: row.id,
            pId: this.$route.query.id,
            name: this.$route.query.name,
            zoneId: this.zoneId
          }
        })
      }
    },
    handleToggleModule (row) {
      const TIPS = row.status !== 1 ? '此操作将启用模块， 是否继续?' : '此操作将禁用模块， 是否继续?'
      const NEXT_STATUS = row.status !== 1 ? 1 : 2
      this.$confirm(TIPS, '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'loading', true)
          toggleCategoryItemAD({
            id: row.id,
            status: NEXT_STATUS
          }).then(res => {
            if (res.code === 200) {
              this.$set(row, 'status', NEXT_STATUS)
            }
          }).finally(() => {
            this.$set(row, 'loading', false)
          })
        })
    },
    handleViewAdList (row) {
      // int64 moduleType = 32;// 模块类型 （1：产品模块，2：广告模块）
      if (row.moduleType === 1) {
        this.$router.push({
          name: 'ADList',
          query: {
            id: row.id,
            name: (row.titleDetail || {}).en || '未知',
            style: row.style,
            zoneId: this.zoneId
          }
        })
      } else if (row.moduleType === 2) {
        this.$router.push({
          name: 'RealADList',
          query: {
            id: row.id,
            name: (row.titleDetail || {}).en || '未知',
            style: row.style,
            zoneId: this.zoneId
          }
        })
      }
    },
    handleDeleteCategoryItemAD (row) {
      this.$confirm('此操作将删除模块, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          deleteCategoryItemAD({
            id: row.id
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryModuleList()
            }
          }).finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
        })
    }
  }
}
</script>

<style scoped lang="less">

</style>
